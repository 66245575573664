<div class="tw-mt-5 tw-flex tw-justify-center" *ngIf="loading">
  <div>
    <img class="tw-mb-4 logo logo-themed" alt="Bitwarden" />
    <p class="tw-text-center">
      <i
        class="bwi bwi-spinner bwi-spin bwi-2x tw-text-muted"
        title="{{ 'loading' | i18n }}"
        aria-hidden="true"
      ></i>
      <span class="tw-sr-only">{{ "loading" | i18n }}</span>
    </p>
  </div>
</div>
<div *ngIf="!loading && !authed">
  <p bitTypography="body1" class="tw-text-center">
    {{ providerName }}
    <span bitTypography="body1" class="tw-font-bold">{{ email }}</span>
  </p>
  <p bitTypography="body1">{{ "joinProviderDesc" | i18n }}</p>
  <hr />
  <div class="tw-flex tw-gap-2">
    <a
      bitButton
      buttonType="primary"
      routerLink="/login"
      [queryParams]="{ email: email }"
      [block]="true"
    >
      {{ "logIn" | i18n }}
    </a>
    <a
      bitButton
      buttonType="primary"
      [routerLink]="registerRoute$ | async"
      [queryParams]="{ email: email }"
      [block]="true"
    >
      {{ "createAccount" | i18n }}
    </a>
  </div>
</div>
