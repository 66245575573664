<bit-card *ngFor="let credential of credentials$ | async" class="tw-mb-2">
  <div class="tw-flex tw-justify-between tw-items-center">
    <div class="tw-flex tw-flex-col">
      <h2 bitTypography="h6">{{ credential.credential }}</h2>
      <span class="tw-text-muted" bitTypography="body1">{{
        credential.generationDate | date: "medium"
      }}</span>
    </div>
    <button
      bitIconButton="bwi-clone"
      bitSuffix
      size="small"
      type="button"
      [appCopyClick]="credential.credential"
      [ariaLabel]="'copyPassword' | i18n"
      [appA11yTitle]="'copyPassword' | i18n"
      showToast
    ></button>
  </div>
</bit-card>
